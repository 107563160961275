import React, { useEffect, useState } from "react";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import  TableCell from '@mui/material/TableCell';
import  TableContainer from '@mui/material/TableContainer';
import  TableHead from '@mui/material/TableHead';
import  TableRow from '@mui/material/TableRow';
import  Paper from '@mui/material/Paper';
import './Usersanddevicespage.css'
import Menu from"../../../assets/images/menuDotsIcon.png"
import Profile1 from '../../../assets/images/profileCoverDp.png'
import Rectangle from "../../../assets/images/rectangleShape.png"
import { TabsContext } from '@mui/base';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import UnfoldMoreIcon from '@mui/icons-material/UnfoldMore';
import { useDispatch, useSelector } from "react-redux";
import { getAllDevices } from '../../../Redux/Actions';

function createData(name,water,devices,cleaning,status,updated){
return{name,water,devices,cleaning,status,updated};
}

const rows=[
  createData("Sydney Boyer","0.5/5L",2,"09 Dec 2019","Disconnected","05:18AM"),
  createData("Todd Morar","0.5/5L",3,"09 Dec 2019","connected","05:18AM"),
  createData("Clayton Hackett","0.5/5L",1,"09 Dec 2019","Disconnected","05:18AM"),
  createData("Sydney Boyer","0.5/5L",1,"09 Dec 2019","Disconnected","05:18AM"),
  createData("Todd Morar","0.5/5L",1,"09 Dec 2019","Disconnected","05:18AM"),
  createData("Clayton Hackett","0.5/5L",1,"09 Dec 2019","Disconnected","05:18AM"),
  createData("Sydney Boyer","0.5/5L",1,"09 Dec 2019","Disconnected","05:18AM"),
  createData("Todd Morar","0.5/5L",1,"09 Dec 2019","Disconnected","05:18AM"),
  createData("Sydney Boyer","0.5/5L",1,"09 Dec 2019","Disconnected","05:18AM"),
  createData("Clayton Hackett","0.5/5L",1,"09 Dec 2019","Disconnected","05:18AM"),
]


export default function UsersAndDevicesPage() {

//   let dispatch = useDispatch();

//   const deviceSet = useSelector(device => device.Device?.deviceResponse)
  
//   useEffect(() => {
//     dispatch(getAllDevices()); 
//   //   setUserID(loginResponse?.data)
// }, []);

let dispatch = useDispatch();

const payment = useSelector((addpay) => addpay.Device.deviceResponse)

console.log("payment data",payment.data)

useEffect(() => {
  dispatch(getAllDevices()); 
//   setUserID(loginResponse?.data)
}, []);
  return (
    <>
    <hr></hr>
        <div>
            <div className='usersAndDeviceHead'>
                <p>Users & Devices</p>
                <div className='buttons'>
                    <button className='b1'><UnfoldMoreIcon className='foldedIcon'/>Sort by : Date added</button>
                    <button  className='b2'>+ Add an user</button>
                </div>
            </div>
            <div className='tableContainer' >
              <TableContainer>
                <Table>
                  <TableHead>
                    <TableRow>
                    <TableCell className='tableHead'>  </TableCell>
                      <TableCell className='tableHead'>USER NAME</TableCell>
                      <TableCell className='tableHead'>WATER LEVEL</TableCell>
                      <TableCell className='tableHead'>NO.OF DEVICE</TableCell>
                      <TableCell className='tableHead'>NEXT CLEANING</TableCell>
                      <TableCell className='tableHead'>STATUS</TableCell>
                      <TableCell className='tableHead'>LAST UPDATED</TableCell>
                     </TableRow>
                  </TableHead>
                  <TableBody>
                    {payment.data?.map((row)=>{ return row.devices.map((x)=>(
                      <>
                      <TableRow
                      key={row.name} className='bodyHead'
                      >
                        <TableCell  className='tableBody1' align="left"><img className="img1" src={Rectangle}/><img  className="img2" src={Profile1}/></TableCell>
                        <TableCell  className='tableBody' align="left">{x.username}</TableCell>
                        <TableCell className='tableBody' align="left">{x.waterlevel}</TableCell>
                        <TableCell className='tableBody' align="left">{row.devicesCount}</TableCell>
                        <TableCell className='tableBody' align="left">{new Date(`${x.nextmaintenance}`).toLocaleDateString("en-US",{ year: 'numeric', month: 'short', day: 'numeric' })}</TableCell>
                        <TableCell className='tableBodyClean' align="left"><span  style={{
                                color: x.devicestatus === 'Disconnected' ? '#E46A11' : x.devicestatus === 'ACTIVE' ? '#0D894F' : '#545F71', 
                                backgroundColor: x.devicestatus === 'INACTIVE' ? '#FDF1E8' : x.devicestatus === 'ACTIVE' ? '#E7F4EE' : '#EEF1F4',
                                width : 'fit-content',
                                borderRadius: '100px',
                                padding: '4px 12px', 
                               textAlign:'center',  

                                }}>{x.devicestatus}</span></TableCell>
                        <TableCell className='tableBody' align="left">{new Date(`${x.lastupdated}`).toLocaleDateString("en-US",{ year: 'numeric', month: 'short', day: 'numeric' })}</TableCell>
                        <TableCell className='tableBodyLast'><img src={Menu}/></TableCell>
                      </TableRow>
                     <TableRow className='emptyRow'>
                      <TableCell className='emptyCell'></TableCell>
                      <TableCell className='emptyCell'></TableCell>
                      <TableCell className='emptyCell'></TableCell>
                      <TableCell className='emptyCell'></TableCell>
                      <TableCell className='emptyCell'></TableCell>
                      <TableCell className='emptyCell'></TableCell>
                      <TableCell className='emptyCell'></TableCell>
                     </TableRow>
                      </>
                    ))})}
                  </TableBody>
                </Table>
              </TableContainer>
            </div>
        </div>
    </>
    
  )
}









// import React, { useEffect, useState } from "react";
// import { MDBDataTable } from 'mdbreact';
// import './Usersanddevicespage.css';
// import Menu from "../../../assets/images/menuDotsIcon.png";
// import Profile1 from '../../../assets/images/profileCoverDp.png';
// import Rectangle from "../../../assets/images/rectangleShape.png";
// import { useDispatch, useSelector } from "react-redux";
// import { getAllDevices } from '../../../Redux/Actions';
// import UnfoldMoreIcon from '@mui/icons-material/UnfoldMore';

// export default function UsersAndDevicesPage() {

//     const dispatch = useDispatch();
//     const deviceSet = useSelector(device => device.Device?.deviceResponse || {}); // Default to empty object if undefined
//     const [sortOrder, setSortOrder] = useState('asc'); // State to track sorting order

//     useEffect(() => {
//         dispatch(getAllDevices());
//     }, [dispatch]);

//     // Function to toggle sorting by nextmaintenance (Next Cleaning date)
//     const handleSortByDate = () => {
//         const newOrder = sortOrder === 'asc' ? 'desc' : 'asc';
//         setSortOrder(newOrder);
//     };

//     // Check if deviceSet.data is available before mapping
//     const sortedRows = deviceSet.data ? deviceSet.data.map(row => 
//         row.devices.map(device => ({
//             profile: (
//                 <div className="profileContainer">
//                     <img className="img1" src={Rectangle} alt="Background" />
//                     <img className="img2" src={Profile1} alt="Profile" />
//                 </div>
//             ),
//             username: device.username,
//             waterlevel: device.waterlevel,
//             devicesCount: row.devicesCount,
//             nextmaintenance: new Date(device.nextmaintenance).toLocaleDateString("en-US", { year: 'numeric', month: 'short', day: 'numeric' }),
//             nextmaintenanceDate: new Date(device.nextmaintenance), // Raw date for sorting
//             devicestatus: (
//                 <span style={{
//                     color: device.devicestatus === 'Disconnected' ? '#E46A11' : device.devicestatus === 'ACTIVE' ? '#0D894F' : '#545F71',
//                     backgroundColor: device.devicestatus === 'Disconnected' ? '#FDF1E8' : device.devicestatus === 'ACTIVE' ? '#E7F4EE' : '#EEF1F4',
//                     borderRadius: '100px',
//                     padding: '4px 12px',
//                     textAlign: 'center'
//                 }}>
//                     {device.devicestatus}
//                 </span>
//             ),
//             lastupdated: new Date(device.lastupdated).toLocaleDateString("en-US", { year: 'numeric', month: 'short', day: 'numeric' }),
//             menu: <img src={Menu} alt="Menu" />
//         }))
//     ).flat() : []; // Handle empty or undefined data safely

//     // Sort rows by 'nextmaintenanceDate' when sorting is triggered
//     const sortedData = sortOrder === 'asc'
//         ? sortedRows.sort((a, b) => new Date(a.nextmaintenanceDate) - new Date(b.nextmaintenanceDate))
//         : sortedRows.sort((a, b) => new Date(b.nextmaintenanceDate) - new Date(a.nextmaintenanceDate));

//     const data = {
//         columns: [
//             { label: '', field: 'profile', sort: 'asc', width: 150 },
//             { label: 'User Name', field: 'username', sort: 'asc', width: 200 },
//             { label: 'Water Level', field: 'waterlevel', sort: 'asc', width: 100 },
//             { label: 'No. of Devices', field: 'devicesCount', sort: 'asc', width: 100 },
//             { label: 'Next Cleaning', field: 'nextmaintenance', sort: 'asc', width: 150 },
//             { label: 'Status', field: 'devicestatus', sort: 'asc', width: 100 },
//             { label: 'Last Updated', field: 'lastupdated', sort: 'asc', width: 150 },
//             { label: '', field: 'menu', sort: 'asc', width: 50 }
//         ],
//         rows: sortedData
//     };

//     return (
//         <>
//             <hr />
//             <div>
//                 <div className='usersAndDeviceHead'>
//                     <p>Users & Devices</p>
//                     <div className='buttons'>
//                         <button className='b1' onClick={handleSortByDate}>
//                             <UnfoldMoreIcon className='foldedIcon' />
//                             Sort by: Next Cleaning
//                         </button>
//                         <button className='b2'>+ Add a user</button>
//                     </div>
//                 </div>
//                 <div className='tableContainer'>
//                     <MDBDataTable
//                         responsive
//                         data={data}
//                         entries={10}
//                         noBottomColumns
//                     />
//                 </div>
//             </div>
//         </>
//     );
// }
