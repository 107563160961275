  // import React, { useCallback, useEffect, useState } from "react";
  // import { MDBDataTable } from "mdbreact";
  // import Styles from "./DeviceManagement.css"
  // import { useDispatch, useSelector } from "react-redux";
  // import { getAllDeviceListById,getDeviceDelete } from "../../../Redux/Actions";
  // import Button from '@mui/material/Button';
  // import Menu from '@mui/material/Menu';
  // import MenuItem from '@mui/material/MenuItem';
  // import MoreVertIcon from '@mui/icons-material/MoreVert';
  // import Box from '@mui/material/Box';
  // import Typography from '@mui/material/Typography';
  // import Modal from '@mui/material/Modal';
  // import TextField from "@mui/material/TextField";
  // import InputAdornment from "@mui/material/InputAdornment";
  // import { ReactComponent as MailIcon } from "../../../assets/SvgIcons/MailIcon.svg";

  // const style = {
  //     position: 'absolute',
  //     top: '50%',
  //     left: '50%',
  //     transform: 'translate(-50%, -50%)',
  //     width: "50%",
  //     bgcolor: 'background.paper',
  //     border: 'px solid  #ECF0F4',
  //     borderRadius: "8px",
  //     boxShadow: 24,
  //     p: 4,
  //   };


  //   export default function DeviceManagementTable() {

  //     const dispatch = useDispatch();
  //     const deviceList = useSelector(device => device.DeviceList.deviceDetails)

      

  //     // let loginResponse = useSelector((state) => state.Login.loginSuccessfull);

  //     // const [useriid , setUserID]=useState()

  //     // const keyName = "userId";

  //     // const filteredData = useriid?.map((item) => {
  //     //   return item[keyName];
  //     // });

  //     // console.log(filteredData,"filteredData")

  //     // const [userDeviceID, setUserDeviceID]=useState(94)

  //     // let loginResponseData = useSelector((state) => state.Login.loginSuccessfull);

  //     // const [userIdData , setUserIdData] = useState(loginResponseData?.data?.userId)
    
  //     // console.log(userIdData)

  //     // const authUserDataDevice = JSON.parse(sessionStorage.getItem("auth"));


  //     // useEffect(() => {
  //     //       dispatch(getAllDeviceListById(authUserDataDevice.userId)); 
  //     //     //   setUserID(loginResponse?.data)
  //     //   }, []);
  //       const [selectedData, setSelectedData] = useState({});

  //       const [show, setShow] = useState(false);
  //       const [anchorEl, setAnchorEl] = useState(null);
  //       const open = Boolean(anchorEl);
  //       const handleClick = (event) => {
  //         setAnchorEl(event.currentTarget);
  //       };
  //       const handleClose = () => {
  //         setAnchorEl(null);
  //       };


      
  //       const [openAlert, setOpenAlert] = useState(false);
  //       const [openAlertEdit, setOpenAlertEdit] = useState(false);

  //       const handleOpenAlertEdit = (device) => {
  //         setOpenAlertEdit(true);
  //         // handleRowClick(device)
  //         console.log('Row clicked:', device);  
  //         // setShow(true);
  //         setSelectedData(device);
  //     }

  //       const handleOpenAlert = (device) => {
  //         setOpenAlert(true);
  //         // handleRowClick(device)
  //         console.log('Row clicked:', device);  
  //         // setShow(true);
  //         setSelectedData(device);
  //     }
  //       const handleCloseAlert = () =>setOpenAlert(false);
  //       const handleCloseAlertEdit = () =>setOpenAlertEdit(false);

  //         console.log(selectedData , "selectedData")
    
  //       const hideModal = () => {
  //         setShow(false);
  //       };

  //       // const handleRowClick = useCallback((device) => {
      
  //       // }, []);
      
    
  // // const Modal = ({  details }) => {
      
  // //     return (
  // //       <div className="modal display-block">
  // //         <section className="modal-main">
  // //           <div className="App">
  // //                      <Typography sx={{ mt: 2 }}>
  // //                         you want to delete ur device {details?.devicename}
  // //                         </Typography>
  // //                         <div className="modelPopDeviceManagentButtonContainer">
  // //                             <button className="deviceDeletePopCancleButton" onClick={()=> handleCloseAlert()}>deny</button>
  // //                             <button className="deviceDeletePopDDeleteButton" onClick={()=> { dispatch(getDeviceDelete(4)); handleCloseAlert()}}>delete</button>
  // //             </div>
  // //           </div>
  // //           <button >close</button>
  // //         </section>
  // //       </div>
  // //     );
  // //   };

  // const [editData, setEditData]=useState({
  //   DeviceName:"Floor10 Tank",
  //   lastRefill:"Oct 10, 2023",
  //   lastMaintanace:"Oct 10, 2023",
  //   TankCapacity:"10 Gallon",
  //   TankType:"Type 1",

  // })
  //   const data = {
  //     columns: [
  //         {
  //             label: "S.No",
  //             field: "s_no",
  //             sort: "asc",
  //             width: 250,
  //         },
  //         {
  //             label: "Device Name",
  //             field: "name",
  //             sort: "asc",
  //             width: 150,
  //         },
          
  //         {
  //             label: "WATER LEVEL",
  //             field: "waterLevel",
  //             sort: "asc",
  //             width: 200,
  //         },
          
  //         {
  //           label: "LAST UPDATED",
  //           field: "lastUpdated",
  //           sort: "asc",
  //           width: 100,
  //       },
  //         {
  //             label: "STATUS",
  //             field: "status",
  //             sort: "asc",
  //             width: 270,
  //         },
  //         {
  //             label: "LAST REFILL",
  //             field: "lastRefil",
  //             sort: "asc",
  //             width: 100,
  //         },
  //         {
  //             label: "LAST MAINTENENCE",
  //             field: "lastMaintanace",
  //             sort: "asc",
  //             width: 150,
  //         },
        
  //         {
  //             field: "action",
  //             sort: "asc",
  //             width: 100,
  //         },
  //     ],
  //     rows:
  //     deviceList && deviceList?.data.map((  device, index) => {
  //         return ({
  //             s_no: index + 1,
  //             name:device.devicename,
  //             waterLevel:device.tankcapacity,
  //             status: <div
  //                     style={{
  //                     color: device.devicestatus === 'INACTIVE' ? '#E46A11' : device.devicestatus === 'ACTIVE' ? '#0D894F' : '#545F71', 
  //                     backgroundColor: device.devicestatus === 'INACTIVE' ? '#FDF1E8' : device.devicestatus === 'ACTIVE' ? '#E7F4EE' : '#EEF1F4',
  //                     width : 'fit-content',
  //                     borderRadius: '100px',
  //                     padding: '4px 12px', 
  //                     textAlign:'center',

  //                     }}
  //                 ><div>{device.devicestatus}</div></div>,
  //             lastRefil:new Date(`${device.lastrefill}`).toLocaleDateString("en-US",{ year: 'numeric', month: 'short', day: 'numeric' }),
  //             lastMaintanace:new Date(`${device.lastmaintanance}`).toLocaleDateString("en-US",{ year: 'numeric', month: 'short', day: 'numeric' }),
  //             lastUpdated:new Date(`${device.lastrefill}`).toLocaleTimeString("en-US"),
  //             action: <div>
  //                     <Button
  //                     onClick={handleClick}
  //                     >
  //                         <MoreVertIcon />
  //                     </Button>
  //                     <Menu
  //                     anchorEl={anchorEl}
  //                     open={open}
  //                     onClose={handleClose}
  //                     >
  //                     <MenuItem onClick={()=> handleOpenAlertEdit(device,device.deviceid)}>Edit Device</MenuItem>
  //                     <MenuItem onClick={()=> {handleOpenAlert(device,device.deviceid)}}>Delete device</MenuItem>
  //                     </Menu>
  //                     <Modal
  //                         open={openAlert}
  //                         onClose={handleCloseAlert}
  //                         aria-labelledby="modal-modal-title"
  //                         aria-describedby="modal-modal-description"
  //                     >
  //                         <Box sx={style} className="modelPopDeviceManagent">
  //                         <Typography sx={{ mt: 2 }}>
  //                         you want to delete ur device {selectedData.devicename}
  //                         </Typography>
  //                         <div className="modelPopDeviceManagentButtonContainer">
  //                             <button className="deviceDeletePopCancleButton" onClick={()=> handleCloseAlert()}>cancle</button>
  //                             <button className="deviceDeletePopDDeleteButton" onClick={()=> { dispatch(getDeviceDelete(selectedData.deviceid)); handleCloseAlert()}}>delete</button>
  //                         </div>
  //                         </Box>
  //                     </Modal>
  //                     <Modal
  //                         open={openAlertEdit}
  //                         onClose={handleOpenAlertEdit}
  //                         aria-labelledby="modal-modal-title"
  //                         aria-describedby="modal-modal-description"
  //                     >
  //                         <Box sx={style} className="modelPopDeviceManagent">
  //                         <Typography sx={{ mt: 2 }}>
  //                         Edit Device Details
  //                         </Typography>
  //                         <div className="editDeviceContainer">
  //                           <div>
  //                           <Typography sx={{ mt: 2 }}>
  //                         Device Name
  //                         </Typography>
  //                         <TextField
  //                               id="outlined-start-adornment"
  //                               sx={{
  //                                 width: "100%",
  //                                 background: "#F8FAFC",
  //                                 border: "1px solid #E9EDF2",
  //                                 borderRadius: "8px",
  //                               }}
  //                               name="email"
  //                               value={editData.DeviceName}
  //                               placeholder={"My tank #1"}
  //                               // InputProps={{
  //                               //   startAdornment: (
  //                               //     <InputAdornment position="start">
  //                               //       <MailIcon />
  //                               //     </InputAdornment>
  //                               //   ),
  //                               // }}
  //                             />
  //                             </div>
                              
  //                             <div className="Data">
  //                             <div>
  //                           <Typography sx={{ mt: 2 }}>
  //                         Last refill
  //                         </Typography>
  //                         <TextField
  //                               id="outlined-start-adornment"
  //                               sx={{
  //                                 background: "#F8FAFC",
  //                                 border: "1px solid #E9EDF2",
  //                                 borderRadius: "8px",
  //                               }}
  //                               name="date"
  //                               value={editData.lastRefill}
  //                               // InputProps={{
  //                               //   startAdornment: (
  //                               //     <InputAdornment position="start">
  //                               //       <MailIcon />
  //                               //     </InputAdornment>
  //                               //   ),
  //                               // }}
  //                             />
  //                             </div>
  //                             <div>
  //                           <Typography sx={{ mt: 2 }}>
  //                         Last Maintance
  //                         </Typography>
  //                         <TextField
  //                               id="outlined-start-adornment"
  //                               sx={{
  //                                 background: "#F8FAFC",
  //                                 border: "1px solid #E9EDF2",
  //                                 borderRadius: "8px",
  //                               }}
  //                               name="lastmaintance"
  //                               value={editData.lastMaintanace}
  //                               // placeholder={"Enter your email or phone"}
  //                               // InputProps={{
  //                               //   startAdornment: (
  //                               //     <InputAdornment position="start">
  //                               //       <MailIcon />
  //                               //     </InputAdornment>
  //                               //   ),
  //                               // }}
  //                             />
  //                             </div>
  //                             </div>
  //                             <div className="Data">
  //                             <div>
  //                           <Typography sx={{ mt: 2 }}>
  //                         Tank capacity
  //                         </Typography>
  //                         <TextField
  //                               id="outlined-start-adornment"
  //                               sx={{
  //                                 background: "#F8FAFC",
  //                                 border: "1px solid #E9EDF2",
  //                                 borderRadius: "8px",
  //                               }}
  //                               name="tankcapacity"
  //                               value={editData.TankCapacity}
  //                               // placeholder={"Enter your email or phone"}
  //                               // InputProps={{
  //                               //   startAdornment: (
  //                               //     <InputAdornment position="start">
  //                               //       <MailIcon />
  //                               //     </InputAdornment>
  //                               //   ),
  //                               // }}
  //                             />
  //                             </div>
  //                             <div>
  //                           <Typography sx={{ mt: 2 }}>
  //                         Tank Type
  //                         </Typography>
  //                         <TextField
  //                               id="outlined-start-adornment"
  //                               sx={{
  //                                 background: "#F8FAFC",
  //                                 border: "1px solid #E9EDF2",
  //                                 borderRadius: "8px",
  //                               }}
  //                               name="tanktype"
  //                               value={editData.TankType}
  //                               // placeholder={"Enter your email or phone"}
  //                               // InputProps={{
  //                               //   startAdornment: (
  //                               //     <InputAdornment position="start">
  //                               //       <MailIcon />
  //                               //     </InputAdornment>
  //                               //   ),
  //                               // }}
  //                             />
  //                             </div>
  //                             </div>
  //                         </div>
  //                         <button onClick={()=>setOpenAlertEdit(false) } className="EditDeviceButton">Update</button >
  //                         </Box>
  //                     </Modal>
  //                     {/* {show && <Modal details={selectedData} handleClose={hideModal} />} */}
  //                 </div>,
  //         })
  //     })
  //   };

  //   return (
  //     <div className={Styles.activeTable}>
  //       <MDBDataTable responsive data={data}  entries={10}/>
  //     </div>
  //   );
  // };




































































// import React, { useCallback, useEffect, useState } from "react";
// import { MDBDataTable } from "mdbreact";
// import Styles from "./DeviceManagement.css";
// import { useDispatch, useSelector } from "react-redux";
// import { getAllDeviceListById, getDeviceDelete } from "../../../Redux/Actions";
// import Button from '@mui/material/Button';
// import Menu from '@mui/material/Menu';
// import MenuItem from '@mui/material/MenuItem';
// import MoreVertIcon from '@mui/icons-material/MoreVert';
// import Box from '@mui/material/Box';
// import Typography from '@mui/material/Typography';
// import Modal from '@mui/material/Modal';
// import TextField from "@mui/material/TextField";

// const style = {
//   position: 'absolute',
//   top: '50%',
//   left: '50%',
//   transform: 'translate(-50%, -50%)',
//   width: "50%",
//   bgcolor: 'background.paper',
//   border: 'px solid #ECF0F4',
//   borderRadius: "8px",
//   boxShadow: 24,
//   p: 4,
// };

// export default function DeviceManagementTable() {
//   const dispatch = useDispatch();
//   const deviceList = useSelector(state => state.DeviceList.deviceDetails);

//   useEffect(() => {
//     const authUserDataDevice = JSON.parse(sessionStorage.getItem("auth"));
//     if (authUserDataDevice) {
//       dispatch(getAllDeviceListById(authUserDataDevice.userId));
//     }
//   }, [dispatch]);

//   const [selectedData, setSelectedData] = useState({});
//   const [show, setShow] = useState(false);
//   const [anchorEl, setAnchorEl] = useState(null);
//   const [openAlert, setOpenAlert] = useState(false);
//   const [openAlertEdit, setOpenAlertEdit] = useState(false);
//   const [openAlertInfo, setOpenAlertInfo] = useState(false);
//   const [editData, setEditData] = useState({
//     DeviceName: "Floor10 Tank",
//     lastRefill: "Oct 10, 2023",
//     lastMaintanace: "Oct 10, 2023",
//     TankCapacity: "10 Gallon",
//     TankType: "Type 1",
//   });

//   const open = Boolean(anchorEl);
//   const handleClick = (event) => setAnchorEl(event.currentTarget);
//   const handleClose = () => setAnchorEl(null);

//   const handleOpenAlert = (device) => {
//     setOpenAlert(true);
//     setSelectedData(device);
//   };

//   const handleCloseAlert = () => setOpenAlert(false);

//   const handleOpenAlertEdit = (device) => {
//     setOpenAlertEdit(true);
//     setSelectedData(device);
//     setEditData({
//       DeviceName: device.devicename,
//       lastRefill: new Date(device.lastrefill).toLocaleDateString(),
//       lastMaintanace: new Date(device.lastmaintanance).toLocaleDateString(),
//       TankCapacity: device.tankcapacity,
//       TankType: device.tanktype,
//     });
//   };

//   const handleCloseAlertEdit = () => setOpenAlertEdit(false);

//   const handleOpenAlertInfo = (device) => {
//     setSelectedData(device);
//     setOpenAlertInfo(true);
//   };

//   const handleCloseAlertInfo = () => setOpenAlertInfo(false);

//   const handleUpdateDevice = () => {
//     // Implement update logic here
//     setOpenAlertEdit(false);
//   };

//   const data = {
//     columns: [
//       { label: "S.No", field: "s_no", sort: "asc", width: 250 },
//       { label: "Device Name", field: "name", sort: "asc", width: 150 },
//       { label: "WATER LEVEL", field: "waterLevel", sort: "asc", width: 200 },
//       { label: "LAST UPDATED", field: "lastUpdated", sort: "asc", width: 100 },
//       { label: "STATUS", field: "status", sort: "asc", width: 270 },
//       { label: "LAST REFILL", field: "lastRefil", sort: "asc", width: 100 },
//       { label: "LAST MAINTENANCE", field: "lastMaintanace", sort: "asc", width: 150 },
//       { field: "action", sort: "asc", width: 100 },
//     ],
//     rows: deviceList && deviceList.data.map((device, index) => ({
//       s_no: index + 1,
//       name: device.devicename,
//       waterLevel: device.tankcapacity,
//       status: (
//         <div
//           style={{
//             color: device.devicestatus === 'INACTIVE' ? '#E46A11' : device.devicestatus === 'ACTIVE' ? '#0D894F' : '#545F71',
//             backgroundColor: device.devicestatus === 'INACTIVE' ? '#FDF1E8' : device.devicestatus === 'ACTIVE' ? '#E7F4EE' : '#EEF1F4',
//             width: 'fit-content',
//             borderRadius: '100px',
//             padding: '4px 12px',
//             textAlign: 'center',
//           }}
//         >
//           <div>{device.devicestatus}</div>
//         </div>
//       ),
//       lastRefil: new Date(device.lastrefill).toLocaleDateString("en-US", { year: 'numeric', month: 'short', day: 'numeric' }),
//       lastMaintanace: new Date(device.lastmaintanance).toLocaleDateString("en-US", { year: 'numeric', month: 'short', day: 'numeric' }),
//       lastUpdated: new Date(device.lastrefill).toLocaleTimeString("en-US"),
//       action: (
//         <div>
//           <Button onClick={handleClick}>
//             <MoreVertIcon />
//           </Button>
//           <Menu
//             anchorEl={anchorEl}
//             open={open}
//             onClose={handleClose}
//           >
//             <MenuItem onClick={() => handleOpenAlertInfo(device)}>Device Info</MenuItem>
//             <MenuItem onClick={() => handleOpenAlertEdit(device)}>Edit Device</MenuItem>
//             <MenuItem onClick={() => handleOpenAlert(device)}>Delete Device</MenuItem>
//           </Menu>
//           <Modal
//             open={openAlert}
//             onClose={handleCloseAlert}
//             aria-labelledby="modal-modal-title"
//             aria-describedby="modal-modal-description"
//           >
//             <Box sx={style} className="modelPopDeviceManagent">
//               <Typography sx={{ mt: 2 }}>
//                 You want to delete your device {selectedData.devicename}?
//               </Typography>
//               <div className="modelPopDeviceManagentButtonContainer">
//                 <Button onClick={handleCloseAlert} variant="outlined">Cancel</Button>
//                 <Button onClick={() => { dispatch(getDeviceDelete(selectedData.deviceid)); handleCloseAlert(); }} variant="contained" color="error">Delete</Button>
//               </div>
//             </Box>
//           </Modal>
//           <Modal
//             open={openAlertEdit}
//             onClose={handleCloseAlertEdit}
//             aria-labelledby="modal-modal-title"
//             aria-describedby="modal-modal-description"
//           >
//             <Box sx={style} className="modelPopDeviceManagent">
//               <Typography sx={{ mt: 2 }}>
//                 Edit Device Details
//               </Typography>
//               <div className="editDeviceContainer">
//                 <TextField
//                   label="Device Name"
//                   fullWidth
//                   sx={{ mb: 2, background: "#F8FAFC", border: "1px solid #E9EDF2", borderRadius: "8px" }}
//                   value={editData.DeviceName}
//                   onChange={(e) => setEditData(prev => ({ ...prev, DeviceName: e.target.value }))}
//                 />
//                 <TextField
//                   label="Last Refill"
//                   fullWidth
//                   sx={{ mb: 2, background: "#F8FAFC", border: "1px solid #E9EDF2", borderRadius: "8px" }}
//                   value={editData.lastRefill}
//                   onChange={(e) => setEditData(prev => ({ ...prev, lastRefill: e.target.value }))}
//                 />
//                 <TextField
//                   label="Last Maintenance"
//                   fullWidth
//                   sx={{ mb: 2, background: "#F8FAFC", border: "1px solid #E9EDF2", borderRadius: "8px" }}
//                   value={editData.lastMaintanace}
//                   onChange={(e) => setEditData(prev => ({ ...prev, lastMaintanace: e.target.value }))}
//                 />
//                 <TextField
//                   label="Tank Capacity"
//                   fullWidth
//                   sx={{ mb: 2, background: "#F8FAFC", border: "1px solid #E9EDF2", borderRadius: "8px" }}
//                   value={editData.TankCapacity}
//                   onChange={(e) => setEditData(prev => ({ ...prev, TankCapacity: e.target.value }))}
//                 />
//                 <TextField
//                   label="Tank Type"
//                   fullWidth
//                   sx={{ mb: 2, background: "#F8FAFC", border: "1px solid #E9EDF2", borderRadius: "8px" }}
//                   value={editData.TankType}
//                   onChange={(e) => setEditData(prev => ({ ...prev, TankType: e.target.value }))}
//                 />
//                 <div className="editDeviceButtonsContainer">
//                   <Button onClick={handleCloseAlertEdit} variant="outlined">Cancel</Button>
//                   <Button onClick={handleUpdateDevice} variant="contained" color="primary">Update</Button>
//                 </div>
//               </div>
//             </Box>
//           </Modal>
//           <Modal
//             open={openAlertInfo}
//             onClose={handleCloseAlertInfo}
//             aria-labelledby="modal-modal-title"
//             aria-describedby="modal-modal-description"
//           >
//             <Box sx={style} className="modelPopDeviceManagent">
//               <Typography sx={{ mt: 2 }}>
//                 Device Information
//               </Typography>
//               <div className="deviceInfoContainer">
//                 <TextField
//                   label="Device Name"
//                   fullWidth
//                   sx={{ mb: 2, background: "#F8FAFC", border: "1px solid #E9EDF2", borderRadius: "8px" }}
//                   value={selectedData.devicename || ''}
//                   InputProps={{ readOnly: true }}
//                 />
//                 <TextField
//                   label="Last Refill"
//                   fullWidth
//                   sx={{ mb: 2, background: "#F8FAFC", border: "1px solid #E9EDF2", borderRadius: "8px" }}
//                   value={new Date(selectedData.lastrefill).toLocaleDateString() || ''}
//                   InputProps={{ readOnly: true }}
//                 />
//                 <TextField
//                   label="Last Maintenance"
//                   fullWidth
//                   sx={{ mb: 2, background: "#F8FAFC", border: "1px solid #E9EDF2", borderRadius: "8px" }}
//                   value={new Date(selectedData.lastmaintanance).toLocaleDateString() || ''}
//                   InputProps={{ readOnly: true }}
//                 />
//                 <TextField
//                   label="Tank Capacity"
//                   fullWidth
//                   sx={{ mb: 2, background: "#F8FAFC", border: "1px solid #E9EDF2", borderRadius: "8px" }}
//                   value={selectedData.tankcapacity || ''}
//                   InputProps={{ readOnly: true }}
//                 />
//                 <TextField
//                   label="Tank Type"
//                   fullWidth
//                   sx={{ mb: 2, background: "#F8FAFC", border: "1px solid #E9EDF2", borderRadius: "8px" }}
//                   value={selectedData.tanktype || ''}
//                   InputProps={{ readOnly: true }}
//                 />
//                 <div className="infoDeviceButtonsContainer">
//                   <Button onClick={handleCloseAlertInfo} variant="outlined">Close</Button>
//                 </div>
//               </div>
//             </Box>
//           </Modal>
//         </div>
//       ),
//     })),
//   };

//   return (
//     <div>
//       <MDBDataTable
//         striped
//         bordered
//         hover
//         data={data}
//         responsive
//       />
//     </div>
//   );
// }




















// import React, { useState } from "react";
// import { MDBDataTable } from "mdbreact";
// import Styles from "./DeviceManagement.css";
// import { useDispatch, useSelector } from "react-redux";
// import { getAllDeviceListById, getDeviceDelete } from "../../../Redux/Actions";
// import Button from '@mui/material/Button';
// import Menu from '@mui/material/Menu';
// import MenuItem from '@mui/material/MenuItem';
// import MoreVertIcon from '@mui/icons-material/MoreVert';
// import Box from '@mui/material/Box';
// import Typography from '@mui/material/Typography';
// import Modal from '@mui/material/Modal';

// const style = {
//   position: 'absolute',
//   top: '50%',
//   left: '50%',
//   transform: 'translate(-50%, -50%)',
//   width: "50%",
//   bgcolor: 'background.paper',
//   border: 'px solid #ECF0F4',
//   borderRadius: "8px",
//   boxShadow: 24,
//   p: 4,
// };

// export default function DeviceManagementTable() {
//   const dispatch = useDispatch();
//   const deviceList = useSelector(state => state.DeviceList.deviceDetails);

//   const [anchorEl, setAnchorEl] = useState(null);
//   const [selectedData, setSelectedData] = useState({});
//   const [openAlert, setOpenAlert] = useState(false);
//   const [openAlertInfo, setOpenAlertInfo] = useState(false);
//   const [openEdit, setOpenEdit] = useState(false);

//   const open = Boolean(anchorEl);

//   const handleClick = (event, device) => {
//     setAnchorEl(event.currentTarget);
//     setSelectedData(device);
//   };

//   const handleClose = () => {
//     setAnchorEl(null);
//   };

//   const handleOpenAlert = () => {
//     setOpenAlert(true);
//   };

//   const handleCloseAlert = () => {
//     setOpenAlert(false);
//   };

//   const handleOpenAlertInfo = () => {
//     setOpenAlertInfo(true);
//   };

//   const handleCloseAlertInfo = () => {
//     setOpenAlertInfo(false);
//   };

//   const handleOpenEdit = () => {
//     setOpenEdit(true);
//   };

//   const handleCloseEdit = () => {
//     setOpenEdit(false);
//   };

//   const handleDeleteDevice = () => {
//     dispatch(getDeviceDelete(selectedData.deviceid));
//     handleCloseAlert();
//     handleClose();
//   };
  

//   const handleEditDevice = () => {
//     // Implement edit device logic here
//     handleCloseEdit();
//     handleClose();
//   };

//   const data = {
//     columns: [
//       {
//         label: "S.No",
//         field: "s_no",
//         sort: "asc",
//         width: 250,
//       },
//       {
//         label: "Device Name",
//         field: "name",
//         sort: "asc",
//         width: 150,
//       },
//       {
//         label: "WATER LEVEL",
//         field: "waterLevel",
//         sort: "asc",
//         width: 200,
//       },
//       {
//         label: "LAST UPDATED",
//         field: "lastUpdated",
//         sort: "asc",
//         width: 100,
//       },
//       {
//         label: "STATUS",
//         field: "status",
//         sort: "asc",
//         width: 270,
//       },
//       {
//         label: "LAST REFILL",
//         field: "lastRefil",
//         sort: "asc",
//         width: 100,
//       },
//       {
//         label: "LAST MAINTENANCE",
//         field: "lastMaintanace",
//         sort: "asc",
//         width: 150,
//       },
//       {
//         field: "action",
//         sort: "asc",
//         width: 100,
//       },
//     ],
//     rows: deviceList && deviceList?.data?.map((device, index) => ({
//       s_no: index + 1,
//       name: device.devicename,
//       waterLevel: device.tankcapacity,
//       status: (
//         <div
//           style={{
//             color: device.devicestatus === 'INACTIVE' ? '#E46A11' : device.devicestatus === 'ACTIVE' ? '#0D894F' : '#545F71',
//             backgroundColor: device.devicestatus === 'INACTIVE' ? '#FDF1E8' : device.devicestatus === 'ACTIVE' ? '#E7F4EE' : '#EEF1F4',
//             width: 'fit-content',
//             borderRadius: '100px',
//             padding: '4px 12px',
//             textAlign: 'center',
//           }}
//         >
//           <div>{device.devicestatus}</div>
//         </div>
//       ),
//       lastRefil: new Date(device.lastrefill).toLocaleDateString("en-US", { year: 'numeric', month: 'short', day: 'numeric' }),
//       lastMaintanace: new Date(device.lastmaintanance).toLocaleDateString("en-US", { year: 'numeric', month: 'short', day: 'numeric' }),
//       lastUpdated: new Date(device.lastrefill).toLocaleTimeString("en-US"),
//       action: (
//         <div>
//           <Button onClick={(event) => handleClick(event, device)}>
//             <MoreVertIcon />
//           </Button>
//           <Menu
//             anchorEl={anchorEl}
//             open={Boolean(anchorEl)}
//             onClose={handleClose}
//           >
//             <MenuItem onClick={handleOpenAlertInfo}>Device Info</MenuItem>
//             <MenuItem onClick={handleOpenEdit}>Edit Device</MenuItem>
//             <MenuItem onClick={handleOpenAlert}>Delete Device</MenuItem>
//           </Menu>
//           <Modal
//             open={openAlert}
//             onClose={handleCloseAlert}
//             aria-labelledby="delete-device-modal"
//           >
//             <Box sx={style}>
//               <Typography sx={{ mt: 2 }}>
//                 Do you want to delete device {selectedData.devicename}?
//               </Typography>
//               <Button onClick={handleCloseAlert}>Cancel</Button>
//               <Button onClick={handleDeleteDevice}>Delete</Button>
//             </Box>
//           </Modal>
//           <Modal
//             open={openAlertInfo}
//             onClose={handleCloseAlertInfo}
//             aria-labelledby="device-info-modal"
//           >
//             <Box sx={style}>
//               <Typography sx={{ mt: 2 }}>
//                 Device Information
//               </Typography>
//               <Typography sx={{ mt: 2 }}>
//                 Device Name: {selectedData.devicename}
//               </Typography>
//               <Typography sx={{ mt: 2 }}>
//                 Last Refill: {new Date(selectedData.lastrefill).toLocaleDateString("en-US")}
//               </Typography>
//               <Typography sx={{ mt: 2 }}>
//                 Last Maintenance: {new Date(selectedData.lastmaintanance).toLocaleDateString("en-US")}
//               </Typography>
//               <Typography sx={{ mt: 2 }}>
//                 Tank Capacity: {selectedData.tankcapacity}
//               </Typography>
//               <Typography sx={{ mt: 2 }}>
//                 Tank Type: {selectedData.tanktype}
//               </Typography>
//               <Button onClick={handleCloseAlertInfo}>Close</Button>
//             </Box>
//           </Modal>
//           <Modal
//             open={openEdit}
//             onClose={handleCloseEdit}
//             aria-labelledby="edit-device-modal"
//           >
//             <Box sx={style}>
//               <Typography sx={{ mt: 2 }}>
//                 Edit Device
//               </Typography>
//               {/* Add your edit device form here */}
//               <Button onClick={handleCloseEdit}>Close</Button>
//             </Box>
//           </Modal>
//         </div>
//       ),
//     }))
//   };

//   return (
//     <div className={Styles.activeTable}>
//       <MDBDataTable responsive data={data} entries={10} />
//     </div>
//   );
// }





















































































import React, { useEffect, useState } from "react";
import { MDBDataTable } from "mdbreact";
import Styles from "./DeviceManagement.css";
import { useDispatch, useSelector } from "react-redux";
import { getAllDeviceListById, getDeviceDataByDeviceID, getDeviceDelete } from "../../../Redux/Actions";
import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import TextField from "@mui/material/TextField";

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: "50%",
  bgcolor: 'background.paper',
  border: 'px solid #ECF0F4',
  borderRadius: "8px",
  boxShadow: 24,
  p: 4,
};

export default function DeviceManagementTable() {
  const dispatch = useDispatch();
  const deviceList = useSelector((device) => device.DeviceList.deviceDetails);
  const authUser = JSON.parse(sessionStorage.getItem("auth"));

  const getAllDeviceListByIdResponse = useSelector(
    (device) => device.DeviceList.deviceDataByDeviceId
  );
  // Modal states for each row
  const [selectedData, setSelectedData] = useState({});
  const [openAlert, setOpenAlert] = useState(false);
  const [openAlertEdit, setOpenAlertEdit] = useState(false);
  const [editData, setEditData] = useState({
    DeviceName: "",
    lastRefill: "",
    lastMaintanace: "",
    TankCapacity: "",
    TankType: "",
  });

  // Track which row's menu is open
  const [menuAnchorEls, setMenuAnchorEls] = useState({});

  const handleClickMenu = (event, device) => {
    setMenuAnchorEls((prev) => ({ ...prev, [device.deviceid]: event.currentTarget }));
    setSelectedData(device); // Set the clicked device data for modals
  };

  const handleCloseMenu = (deviceId) => {
    setMenuAnchorEls((prev) => ({ ...prev, [deviceId]: null }));
  };

  const handleOpenAlertEdit = () => {
    setOpenAlertEdit(true);
    // Populate edit form with selected device data
    setEditData({
      DeviceName: selectedData.devicename || "",
      lastRefill: new Date(selectedData.lastrefill).toLocaleDateString("en-US", { year: "numeric", month: "short", day: "numeric" }),
      lastMaintanace: new Date(selectedData.lastmaintanance).toLocaleDateString("en-US", { year: "numeric", month: "short", day: "numeric" }),
      TankCapacity: selectedData.tankcapacity || "",
      TankType: selectedData.tanktype || "",
    });
  };

  const handleOpenAlert = () => {
    setOpenAlert(true);
  };

  const handleCloseAlert = () => setOpenAlert(false);
  const handleCloseAlertEdit = () => setOpenAlertEdit(false);

  const handleDeleteDevice = () => {
   dispatch(getDeviceDelete(selectedData.deviceid))
   if (deviceList && deviceList?.statusCode === 200) {
    dispatch(getDeviceDataByDeviceID(authUser?.userId));
  }
  };


  const data = {
    columns: [
      { label: "S.No", field: "s_no", sort: "asc", width: 250 },
      { label: "Device Name", field: "name", sort: "asc", width: 150 },
      { label: "WATER LEVEL", field: "waterLevel", sort: "asc", width: 200 },
      { label: "LAST UPDATED", field: "lastUpdated", sort: "asc", width: 100 },
      { label: "STATUS", field: "status", sort: "asc", width: 270 },
      { label: "LAST REFILL", field: "lastRefil", sort: "asc", width: 100 },
      { label: "LAST MAINTENANCE", field: "lastMaintanace", sort: "asc", width: 150 },
      { field: "action", sort: "asc", width: 100 },
    ],
    rows: deviceList && deviceList?.data.map((device, index) => ({
      s_no: index + 1,
      name: device.devicename,
      waterLevel: device.tankcapacity,
      status: (
        <div
          style={{
            color: device.devicestatus === "INACTIVE" ? "#E46A11" : device.devicestatus === "ACTIVE" ? "#0D894F" : "#545F71",
            backgroundColor: device.devicestatus === "INACTIVE" ? "#FDF1E8" : device.devicestatus === "ACTIVE" ? "#E7F4EE" : "#EEF1F4",
            width: "fit-content",
            borderRadius: "100px",
            padding: "4px 12px",
            textAlign: "center",
          }}
        >
          <div>{device.devicestatus}</div>
        </div>
      ),
      lastRefil: new Date(device.lastrefill).toLocaleDateString("en-US", { year: "numeric", month: "short", day: "numeric" }),
      lastMaintanace: new Date(device.lastmaintanance).toLocaleDateString("en-US", { year: "numeric", month: "short", day: "numeric" }),
      lastUpdated: new Date(device.lastrefill).toLocaleTimeString("en-US"),
      action: (
        <div>
          <Button onClick={(event) => handleClickMenu(event, device)}>
            <MoreVertIcon />
          </Button>
          <Menu
            anchorEl={menuAnchorEls[device.deviceid]}
            open={Boolean(menuAnchorEls[device.deviceid])}
            onClose={() => handleCloseMenu(device.deviceid)}
          >
            <MenuItem onClick={handleOpenAlertEdit}>Edit Device</MenuItem>
            <MenuItem onClick={handleOpenAlert}>Delete Device</MenuItem>
          </Menu>

          {/* Delete Modal */}
          <Modal open={openAlert}
                        onClose={handleCloseAlert}
                        aria-labelledby="modal-modal-title"
                        aria-describedby="modal-modal-description"
                    >
                        <Box sx={style} className="modelPopDeviceManagent">
                    <Typography sx={{ mt: 2 }}>Do you want to delete the device {selectedData.devicename}?</Typography>
                    <div className="modelPopDeviceManagentButtonContainer">
                            <button className="deviceDeletePopCancleButton" onClick={()=> handleCloseAlert()}>Cancel</button>
                            <button className="deviceDeletePopDDeleteButton" onClick={()=> { handleDeleteDevice(); handleCloseAlert()}}>Delete</button>
                        </div>
                        </Box>
                </Modal>

          {/* Edit Modal */}
          <Modal open={openAlertEdit} onClose={handleCloseAlertEdit}>
            <Box sx={style}>
              <Typography sx={{ mt: 2 }}>Edit Device</Typography>
              <TextField fullWidth label="Device Name" value={editData.DeviceName} sx={{ mt: 2 }} />
              <TextField fullWidth label="Last Refill" value={editData.lastRefill} sx={{ mt: 2 }} />
              <TextField fullWidth label="Last Maintenance" value={editData.lastMaintanace} sx={{ mt: 2 }} />
              <TextField fullWidth label="Tank Capacity" value={editData.TankCapacity} sx={{ mt: 2 }} />
              <TextField fullWidth label="Tank Type" value={editData.TankType} sx={{ mt: 2 }} />
              <Button onClick={() => setOpenAlertEdit(false)} sx={{ mt: 2 }}>Update</Button>
            </Box>
          </Modal>
        </div>
      ),
    })),
  };

  return (
      <div className={Styles.activeTable}>
        <MDBDataTable responsive data={data} entries={10} />
      </div>
  );
}
