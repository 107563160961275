import React, { useEffect, useRef } from 'react';
import MarkerIcon from "../../../../assets/images/MapIcon1.png";
import "./mapandgeolocation.css";
import { useDispatch, useSelector } from "react-redux";
import { getAllMaps } from '../../../../Redux/Actions';
import  {  useState } from "react";
import Wave from "react-wavify";
// import Styles from "./Index.module.css";
import ToggleButton from "@mui/material/ToggleButton";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
// import DataTable from "./DataTable";
import { Link } from "react-router-dom";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import TooltpWaterLevel from "../../../../assets/images/TooltpWaterLevel.png";
import { styled } from "@mui/material/styles";
// import { useDispatch, useSelector } from "react-redux";
import {
  getDeviceDataByDeviceID,
  getAllDeviceListByIdResponse,
  getForcostDeviceData,
  getUseageDeviceData,
} from "../../../../Redux/Actions";
import DownArrow from "../../../../assets/images/DownArrow.png";
// import CarouselContent from "./CarouselContent";
import waterdrop from "../../../../assets/videos/waterdrop.mp4";

import Box from '@mui/material/Box';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import NativeSelect from '@mui/material/NativeSelect';
// import { select } from "redux-saga/effects";
import Battery1 from "../../../../assets/images/Artboard 1@4x.png"
import Battery2 from "../../../../assets/images/Artboard 2@4x.png"
import Battery3 from "../../../../assets/images/Artboard 3@4x.png"
import Battery4 from "../../../../assets/images/Artboard 4@4x.png"
import Battery5 from "../../../../assets/images/Artboard 5@4x.png"
import Battery6 from "../../../../assets/images/Artboard 6@4x.png"
import Battery7 from "../../../../assets/images/Artboard 7@4x.png"
import Battery8 from "../../../../assets/images/Artboard 8@4x.png"
import Battery9 from "../../../../assets/images/Artboard 9@4x.png"
import Battery10 from "../../../../assets/images/Artboard 10@4x.png"
import Wifi4 from "../../../../assets/images/wifi4.png"
import Wifi2 from "../../../../assets/images/wifi2.png"
import Wifi1 from "../../../../assets/images/wifi1.png"
import Wifi3 from "../../../../assets/images/wifi3.png"
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import { AreaChart, Area, XAxis, YAxis, CartesianGrid } from "recharts"

const containerStyle = {
  width: '100%',
  height: '400px'
};

const center = {
  lat: 13.08784000,
  lng: 80.27847000  
};

const markers = [
  { id: 1, position: { lat: 13.08784000, lng: 80.27847000 }, icon: MarkerIcon },
  { id: 2, position: { lat: 12.97194000, lng: 77.59369000 }, icon: MarkerIcon },
  { id: 3, position: { lat: 10.85051590, lng: 76.27108330 }, icon: MarkerIcon }
];

function MapAndGeolocation() {
  const dispatch = useDispatch();
  const heatList = useSelector((heatdata) => heatdata.Map.mapResponse);

  useEffect(() => {
    dispatch(getAllMaps()); 
  }, [dispatch]);

  const mapRef = useRef(null);

  useEffect(() => {
    if (window.google && mapRef.current) {
      const map = new window.google.maps.Map(mapRef.current, {
        center: center,
        zoom: 10
      });

      heatList.data?.forEach((card) => {
        // Extract latitude and longitude from devicelocation
        const [latStr, lngStr] = card.devicelocation.split(', ');
        const lat = parseFloat(latStr);
        const lng = parseFloat(lngStr);

        if (!isNaN(lat) && !isNaN(lng)) { // Ensure lat and lng are valid numbers
          const marker = new window.google.maps.Marker({
            position: { lat, lng },
            map: map,
            icon: {
              url: MarkerIcon,
              scaledSize: new window.google.maps.Size(50, 50)
            }
          });
          

          const contentString = `
            <div style="width:248px;gap: 8px;">
              <h3 style="width: 176px;height: 18px;font-size: 18px;font-weight: 800;line-height: 18px;text-align: left;color:#181C20">${card.devicename}</h3>
              <p style="font-family: Figtree;font-size: 10px;font-weight: 400;line-height: 16px;text-align: left;color: #6A7A8A;">${card.createdtime}</p>
              <div style="display:flex; justify-content: space-between; ">
                <div style="width:85px;height:24px;padding: 4px 12px 4px 12px;gap: 8px;border-radius: 100px;opacity: 0px;background: #EAFBF0;"><p style="width: 61px;height: 16px;gap: 0px;opacity: 0px;font-size: 12px;font-weight: 400;line-height: 16px;text-align: center;color: #147138;">${card.deviceStatus}</p></div>
                <div style="width:106px;height:36px;gap: 4px;opacity: 0px;margin-top:-0.5rem">
                  <p style="width: 106px;height: 16px;width: 106px;height: 16px;;color:#6A7A8A;">Last updated</p>
                  <p style="width: 93px;height: 16px;gap: 0px;opacity: 0px;color:#181C20;margin-top:-0.5rem">${card.createdtime}</p>
                </div>
              </div>
              <hr style="margin-top:1rem">
              <div style="display:flex; justify-content: space-between;margin-top:-1rem ">
                <p style="width: 141px;height: 16px;font-size: 12px;font-weight: 400;line-height: 16px;text-align: left;color:#6A7A8A">Water level</p>
                <p style="font-family: Figtree;font-size: 12px;font-weight: 400;line-height: 16px;text-align: left;width: 105px;height: 16px;gap: 0px;opacity: 0px;color:#181C20">${card.waterlevel}</p>
              </div>
              <div style="display:flex; justify-content: space-between;margin-top:-0.5rem  ">
                <p style="width: 151px;height: 16px;gap: 0px;opacity: 0px;width: 171px;height: 16px;gap: 0px;opacity: 0px;color:#6A7A8A">Next cleaning date</p>
                <p style="width: 109px;height: 16px;gap: 0px;opacity: 0px;font-family: Figtree;font-size: 12px;font-weight: 400;line-height: 16px;text-align: left;color:#181C20">${card.nextmaintence}</p>
              </div>
            </div>
          `;

          const infowindow = new window.google.maps.InfoWindow({
            content: contentString
          });

          marker.addListener('click', () => {
            infowindow.open(map, marker); 
          });
        }
      });
    }
  }, [heatList]);

  return (
    <><div>
      <div style={containerStyle} ref={mapRef} />
      </div>
      <div>

      </div>
      
    </>
  );
}

export default MapAndGeolocation;
