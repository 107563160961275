import React, { useEffect, useState, useRef } from "react";
import "./adminUserProfile.css";
import ImageUploader from "react-image-upload";
import "react-image-upload/dist/index.css";
import TextField from "@mui/material/TextField";
import { ReactComponent as MailIcon } from "../../../../assets/SvgIcons/MailIcon.svg";
import MuiPhoneNumber from "material-ui-phone-number";
import { FormControl } from "@mui/material";
import PersonOutlineOutlinedIcon from "@mui/icons-material/PersonOutlineOutlined";
import OutlinedInput from "@mui/material/OutlinedInput";
import InputAdornment from "@mui/material/InputAdornment";
import { ReactComponent as LocationIcon } from "../../../../assets/SvgIcons/LocationIcon.svg";
import { ReactComponent as LocationLocateIcon } from "../../../../assets/SvgIcons/LocationLocateIcon.svg";
import { useDispatch, useSelector } from "react-redux";
import {
  getUsetDetails,
  updateUserProfileDetails,
} from "../../../../Redux/Actions";
import Badge from "@mui/material/Badge";
import Avatar from "@mui/material/Avatar";
import CameraAltOutlinedIcon from "@mui/icons-material/CameraAltOutlined";
import { toast } from "react-toastify";
import { Link, useNavigate } from "react-router-dom";

const UserProfile = () => {
  let dispatch = useDispatch();
  const navigate = useNavigate();
  let loginResponse = useSelector(
    (state) => state.UserProfile.getUserInformation
  );
  const Userr = useSelector((adminn) => adminn.UserProfile.getUserInformation);
  const [userID, setUserID] = useState(Userr?.data?.userid);
  console.log("email", userID, Userr);

  useEffect(() => {
    if (userID) {
      dispatch(getUsetDetails(userID));
    }
  }, [userID]);

  function getImageFileObject(imageFile) {
    console.log({ onAdd: imageFile });
  }
  function runAfterImageDelete(file) {
    console.log({ onDele: file });
  }

  const [profileDetails, setProfileDetails] = useState({
    firstname: "",
    lastname: "",
    phonenumber: "",
    address: "",
    imagedata: null,
  });

  // Populate form fields when Userr is available
  useEffect(() => {
    if (Userr?.data) {
      setProfileDetails({
        firstname: Userr.data.firstname || "",
        lastname: Userr.data.lastname || "",
        phonenumber: Userr.data.phonenumber || "",
        address: Userr.data.address || "",
        imagedata: Userr.data.imagedata || "",
      });
    }
  }, [Userr]);

  // Handle input changes
  // const handleInputChange = (e) => {
  //   console.log(e)
  //   const { name, value } = e.target;
  //   setProfileDetails((prevDetails) => ({
  //     ...prevDetails,
  //     [name]: value,
  //   }));
  // };

  // Handle image and other input changes
  // const handleInputChange = (e) => {
  //   const { name, value, files } = e.target;

  //   // If it's an image file input, handle it separately
  //   if (name === "imagedata" && files && files[0]) {
  //     const imageFile = files[0]; // Get the image file
  //     console.log('imageFile',imageFile);

  //     setProfileDetails((prevDetails) => ({
  //       ...prevDetails,
  //       imagedata: imageFile, // Set the image in the state
  //     }));
  //   } else {
  //     setProfileDetails((prevDetails) => ({
  //       ...prevDetails,
  //       [name]: value, // Handle other inputs normally
  //     }));
  //   }
  // };

  const handlePhoneNumberChange = (value) => {
    setProfileDetails((prevDetails) => ({
      ...prevDetails,
      phonenumber: value, // Just assign the phone number directly
    }));
  };
  // console.log("loginResponse",loginResponse)

  const handleSubmit = () => {
    const updatedProfileDetails = {
      ...profileDetails,
      userid: Userr?.data?.userid, // Add userid from your state
      roleid: Userr?.data?.roleid || null, // Assuming you have roleid in your user data
    };

    dispatch(updateUserProfileDetails(updatedProfileDetails));
    console.log("Userrrr", Userr.statusCode);
    if (Userr.statusCode === 200) {
      //  window.location.reload();
      navigate("/user_dashboard");
    }
  };

  const hiddenFileInput = useRef(null); // Step 1: Create a ref

  const handleInputChange = (e) => {
    const { name, files } = e.target;

    if (name === "imagedata" && files && files[0]) {
      console.log("image size", files[0]);
      if (files[0].size > 5120) {
        toast.error("Image size doesn't exceeds 5KB");
        return;
      }
      const imageFile = files[0]; // Get the image file
      const reader = new FileReader();

      reader.onloadend = () => {
        setProfileDetails((prevDetails) => ({
          ...prevDetails,
          imagedata: reader.result, // Set the Base64 image data in the state
        }));
      };

      reader.readAsDataURL(imageFile); // Read the file as Base64
    } else {
      const { value } = e.target;
      setProfileDetails((prevDetails) => ({
        ...prevDetails,
        [name]: value,
      }));
    }
  };

  const handleImageUploadClick = () => {
    hiddenFileInput.current.click(); // Simulate a click on the hidden input
  };
  return (
    <div className="UserProfileMainContainer">
      {/* {profileDetails.imagedata && (
        <img
          src={profileDetails.imagedata}
          alt="Uploaded"
          style={{ width: "200px", height: "200px", objectFit: "contain" }}
        />
      )} */}
<div className="avatarStyle">
      <Badge
        overlap="circular"
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
        badgeContent={
          <CameraAltOutlinedIcon
            onClick={handleImageUploadClick}
            style={{
              backgroundColor: "#5BD1DC",
              color: "#fff",
              borderRadius: "50%",
              padding: "4px",
              fontSize: "30px",
              cursor: "pointer",
            }}
          />
        }
      >
        <Avatar
          alt="Travis Howard"
          src={profileDetails.imagedata}
          sx={{ width: 125, height: 125 }}
          style={{ cursor: "pointer" }}
        />
      </Badge>
      </div>
      {/* <ImageUploader
        className="imageUploader"
        onFileAdded={handleImageUploadClick} // Trigger file input click
        value={profileDetails.imagedata} // You may want to handle preview if needed
        accept="image/*"
      /> */}
      <input
        type="file"
        accept="image/*"
        style={{ display: "none" }}
        ref={hiddenFileInput} // Attach the ref to the input
        onChange={handleInputChange} // Handle the file selection
        name="imagedata" // Add the name attribute to identify it
      />
      <div className="UserProfileContainer">
        <p className="titleFullName">Full Name</p>
        <TextField
          id="outlined-start-adornment"
          sx={{
            width: "100%",
            border: "1px solid #E9EDF2",
            borderRadius: "8px",
            margin: "1rem 0rem",
          }}
          name="firstname"
          placeholder={"First Name"}
          value={profileDetails.firstname}
          onChange={handleInputChange}
          //   onChange={(e) => setLogin({ ...login, email: e.target.value })}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <PersonOutlineOutlinedIcon />
              </InputAdornment>
            ),
          }}
        />
        <p className="titleLastName">Last Name</p>
        <TextField
          id="outlined-start-adornment"
          sx={{
            width: "100%",
            border: "1px solid #E9EDF2",
            borderRadius: "8px",
          }}
          name="lastname"
          placeholder={"Last Name"}
          value={profileDetails.lastname}
          //   onChange={(e) => setLogin({ ...login, email: e.target.value })}
          onChange={handleInputChange}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <PersonOutlineOutlinedIcon />
              </InputAdornment>
            ),
          }}
        />
        <p className="titleMobileNumber">Mobile Number</p>
        <MuiPhoneNumber
          defaultCountry={"in"}
          name="phonenumber"
          variant="outlined"
          required
          enableLongNumbers
          onChange={handlePhoneNumberChange}
          // onChange={(value) =>
          // setRegistration({ ...registration, phonenumber: value })
          // }
          sx={{
            width: "100%",
            border: "1px solid #E9EDF2",
            borderRadius: "8px",
            margin: "1rem 0rem",
          }}
          value={profileDetails.phonenumber}
          placeholder={"Mobile Number"}
          className="RegistrationPageMobileNumber"
        />
        <p className="titleAddress">Address</p>
        <FormControl sx={{ width: "100%", background: "#fff" }}>
          <OutlinedInput
            startAdornment={
              <InputAdornment position="start">
                <LocationIcon />
              </InputAdornment>
            }
            name="address"
            onChange={handleInputChange}
            //   onChange={(e) =>
            //     SetModelData({
            //       ...modelDdata,
            //       address: e.target.value,
            //     })
            //   }
            placeholder="Urna urna sem, sodales nibh laoreet aliquam adipiscing pulvinar arcu."
            value={profileDetails.address}
            endAdornment={
              <InputAdornment position="end">
                <LocationLocateIcon />
              </InputAdornment>
            }
          />
        </FormControl>
        <button className="UserProfileSummitButton" onClick={handleSubmit}>
          Update
        </button>
      </div>
    </div>
  );
};

export default UserProfile;
