// import React, { useEffect, useState } from 'react'
// // import './MyBookingsTable.scss'
// import CheckBoxOutlineBlankTwoToneIcon from '@mui/icons-material/CheckBoxOutlineBlankTwoTone';
// import {ReactComponent as MyBookingsTableRightIcon} from '../../../../assets/SvgIcons/MyBookingsTableRightIcon.svg'
// import Rectangle from '../../../../assets/images/Rectangle.png'
// import { Link } from 'react-router-dom';
// import { useDispatch, useSelector } from 'react-redux';
// import { getAdminBooking,  } from "../../../../Redux/Actions";

// const upComingStyles ={
//      marginLeft:'20px',
//      padding:'8px 13px',
//      borderRadius: '40px',
//      backgroundColor: '#FEF9F5',
//      justifyContent: 'center',
//      alignItems: 'center',
//      color: '#FF824D',
//     fontSize: '14px',
//     fontWeight: '500',
// }


// const MyCustomerBookings = () => {
//     const dispatch = useDispatch();

//     const getAllBookingDetails = useSelector(booking => booking.Booking.getAdminBookingDetails)

//     console.log(getAllBookingDetails,"getAllBooking xxxx")


//     const [userID, setUserID]=useState(34)

//     useEffect(() => {
//          dispatch(getAdminBooking(userID)); 
//     }, [userID]);



//   return (
//     <div className='MyBookingsTable'>
//         <div className='Bottom'>
//             <table>
//                 <thead>
//                     <tr className='TableHead'>
//                         <th className="TableBody"></th>
//                         <th className="TableBody"></th>
//                         <th  className="Bookingdetails">DEVICE NAME</th>
//                         <th  className="Bookingdetails">CUSTOMER NAME</th>
//                         <th  className="Bookingdetails">SERVICE</th>
//                         <th className="Bookingdetails">BOOKING DATE</th>
//                         <th className="Bookingdetails ">STATUS</th>
//                         <th className="Bookingdetails "></th>
//                     </tr>
//                 </thead>
//                 <tbody>
//                 {getAllBookingDetails?.data?.map((data)=> (
//                     <tr className='TableBody' key={data.id}>
//                         <td><img src={Rectangle} alt='indication' /></td>
//                         <td className='BookingdetailsAdmin'>{data.description}</td>
//                         <td className='techniciandetailsAdmin'>John</td>
//                         <td className='BookingDateAdmin'>{data.description}</td>
//                         <td className='BookingDateAdmin'> {new Date(`${data.bookingdatetime}`).toLocaleDateString("en-US",{ year: 'numeric', month: 'short', day: 'numeric' })} 
                     
//                          </td>
//                         <td className='BookingdetailsAdmin'>   <div
//                                 style={{
//                                 color: data.bookingstatus === 'PENDING' ? '#E46A11' : data.bookingstatus === 'TECHASSIGNED' ? '#0D894F' : '#545F71', 
//                                 backgroundColor: data.bookingstatus === 'PENDING' ? '#FDF1E8' : data.bookingstatus === 'TECHASSIGNED' ? '#E7F4EE' : '#EEF1F4',
//                                 width : 'fit-content',
//                                 borderRadius: '100px',
//                                 padding: '4px 12px', 
//                                textAlign:'center',
//                                margin:'0rem 0.5rem'

//                                 }}
//                             ><div>
//                               <select>
                               
//                              <option disabled>Select an option</option> 
//                              <option selected>{data.bookingstatus}</option>
//                                 <option>Inprogress</option>
//                                 <option>Closed</option>
//                               </select>
//                               </div></div></td>
//                         <td style={{display:'flex', alignItems:'start'}}><div style={{color:'#4D8BFF'}}><Link to="/booking_details" className="LinkedBookingDetails">View booking</Link></div> <div><MyBookingsTableRightIcon/></div> </td>
//                     </tr>
//                 ))}
//                 </tbody>
//                 </table>
//         </div>
//     </div>
//   )
// }

// export default MyCustomerBookings










































import React, { useEffect, useState } from 'react';
import { MDBDataTable } from 'mdbreact';
import { ReactComponent as MyBookingsTableRightIcon } from '../../../../assets/SvgIcons/MyBookingsTableRightIcon.svg';
import Rectangle from '../../../../assets/images/Rectangle.png';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { getAdminBooking } from "../../../../Redux/Actions";
// import './MyBookingsTable.scss'; // Ensure you have appropriate styles
import Styles from "./../../../pages/DeviceManagement/DeviceManagement.css";

const MyCustomerBookings = () => {
  const dispatch = useDispatch();
  const getAllBookingDetails = useSelector(booking => booking.Booking.getAdminBookingDetails);

  const [userID, setUserID] = useState(34);

  useEffect(() => {
    dispatch(getAdminBooking(userID));
  }, [userID, dispatch]);

  // Format the data for MDBDataTable
  const data = {
    columns: [
      { label: 'Device Name', field: 'deviceName', sort: 'asc', width: 200 },
      { label: 'Customer Name', field: 'customerName', sort: 'asc', width: 200 },
      { label: 'Service', field: 'service', sort: 'asc', width: 200 },
      { label: 'Booking Date', field: 'bookingDate', sort: 'asc', width: 200 },
      { label: 'Status', field: 'status', sort: 'asc', width: 200 },
      { label: 'Action', field: 'action', sort: 'asc', width: 200 }
    ],
    rows: getAllBookingDetails?.data?.map(data => ({
      deviceName: data.description, // Adjust according to actual data
      customerName: 'John', // Replace with actual customer name if available
      service: data.description, // Adjust according to actual data
      bookingDate: new Date(data.bookingdatetime).toLocaleDateString("en-US", { year: 'numeric', month: 'short', day: 'numeric' }),
      status: (
        <div
          style={{
            color: data.bookingstatus === 'PENDING' ? '#E46A11' : data.bookingstatus === 'TECHASSIGNED' ? '#0D894F' : '#545F71',
            backgroundColor: data.bookingstatus === 'PENDING' ? '#FDF1E8' : data.bookingstatus === 'TECHASSIGNED' ? '#E7F4EE' : '#EEF1F4',
            width: 'fit-content',
            borderRadius: '100px',
            padding: '4px 12px',
            textAlign: 'center',
            margin: '0rem 0.5rem'
          }}
        >
           <select>
                               
                               <option disabled>Select an option</option> 
                               <option selected>{data.bookingstatus}</option>
                                  <option>Inprogress</option>
                                  <option >Closed</option>
                                </select>
        </div>
      ),
      action: (
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <Link to="/booking_details" className="LinkedBookingDetails" style={{ color: '#4D8BFF' }}>View booking</Link>
          <MyBookingsTableRightIcon />
        </div>
      )
    }))
  };

  return (
    <div className={Styles.activeTable}>
      <MDBDataTable responsive data={data} entries={10} />
    </div>
);
}

export default MyCustomerBookings;
